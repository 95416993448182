<template>
  <section>
    <section>
      <a-form class="form-container" :form="form" @submit="submitForm">
        <a-row type="flex" :gutter="20">
          <a-col :span="12">
            <a-form-item label="批次编号">
              <a-input
                v-decorator="[
                  'code',
                  {
                    rules: [{ required: true, message: '请选择填写批次编号' }],
                  },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="供应商">
              <a-input v-decorator="['supplier',{rules:[{required:false,message:'请输入供应商'},{max:30,message:'最大输入30个字符'}]}]" :disabled="true"></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="20">
          <a-col :span="12">
            <a-form-item label="到达时间">
              <a-date-picker
                v-decorator="[
                  'exceptedTime',
                  {
                    rules: [{ required: true, message: '请选择预计到达时间' }],
                  },
                ]"
                @change="onChange"
              >
                <a-icon slot="suffixIcon" type="calendar" />
              </a-date-picker>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="车牌号">
              <a-input
                v-decorator="['carNumber',{rules: [{required:false,message:'请输入车牌号'},{ validator: validator.carNumberReg}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="20">
          <a-col :span="12">
            <a-form-item label="送货人">
              <a-input
                v-decorator="[
                  'deliveryman',
                  {
                    rules: [{required:false,message:'请输入送货人'},{ max: 10, message: '最大输入10个字' }]
                  },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="联系方式">
              <a-input v-decorator="['phone',{
                    rules: [{required:false,message:'请输入联系方式'},{ validator: validator.phoneReg}]}]"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <section class="list-table-container list-table">
          <span style="color: #84ffe9">材料列表</span>
          <a-table
            :bordered="false"
            :data-source="dataSource"
            :pagination="false"
            size="small"
            class="m-t-18"
            :columns="columns"
          >
            <template slot="stockQty" slot-scope="list, text">
              <a-input
                v-model="text.stockQty"
                :min="0"
                :max="text.stockQty"
                type="number"
                style="width: 120px"
              />
            </template>

            <template slot="operation" slot-scope="list, text">
              <a @click="delDataSource(text.id)">删除</a>
            </template>
          </a-table>
          <!-- <a-row class="search-container addData" type="flex" justify="center">
            <a-button @click="addData">新增</a-button>
          </a-row> -->
          <a-row class="totalNum" type="flex" justify="start">
            <span>合计</span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>

            <span>{{ stockQtyNum }}</span>
          </a-row>
        </section>
        <section class="form-container">
          <a-row class="form-btn-group">
            <a-button class="btn-save" html-type="submit"
              ><a-icon type="save" />保存</a-button
            >
            <a-button @click="$router.back()" class="btn-cancel">取消</a-button>
          </a-row>
        </section>
      </a-form>
    </section>

    <a-modal
      centered
      width="880px"
      class="modal-container"
      :title="typeModal.title"
      v-model="typeModal.visible"
      :destroyOnClose="true"
      :footer="false"
    >
      <a-form :form="materialform" @submit="addMaterial">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="材料"
        >
          <a-cascader
            :options="options"
            @change="(value) => onChangeMaterial(value, options)"
            placeholder="请选择"
            :display-render="displayRender"
            v-decorator="[
              'companyNames',
              { rules: [{ required: true, message: '类型名称不能为空' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="分类名称"
        >
          <section class="list-table-container list-table">
            <a-table
              :rowSelection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              :bordered="false"
              :data-source="materialSource"
              size="small"
              class="m-t-36"
              :columns="materialColumns"
              :pagination="false"
              :scroll="{ y: 500 }"
            >
            </a-table>
          </section>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" html-type="submit"
            ><a-icon type="save" />保存</a-button
          >
          <a-button class="btn-cancel" @click="typeModal.visible = false"
            >取消</a-button
          >
        </a-row>
      </a-form>
    </a-modal>
  </section>
</template>
<script>
import moment from "moment";
import validator from '@/until/validator'
export default {
  data() {
    return {
      validator,
      typeModal: {
        visible: false,
        title: "添加",
      },
      orderInfo: [],
      materialPageNum: 1,
      materialTotal: 0,
      form: this.$form.createForm(this),
      materialform: this.$form.createForm(this),
      categoryId: "",
      supplier: "",
      selectedRowKeys: [],
      columns: [
        { title: "序号", dataIndex: "order", align: "center", width: 200 },
        {
          title: "材料名称",
          dataIndex: "materialName",
          align: "center",
          width: 200,
        },
        { title: "品牌", dataIndex: "brand", align: "center", width: 200 },
        {
          title: "规格型号",
          dataIndex: "type",
          align: "center",
          width: 200,
        },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
          width: 200,
        },
        {
          title: "送货数量",
          dataIndex: "stockQty",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "stockQty" },
        },
        // {
        //   title: "操作",
        //   dataIndex: "operation",
        //   align: "center",
        //   width: 200,
        //   scopedSlots: { customRender: "operation" },
        // },
      ],
      dataSource: [],
      materialSource: [],
      materialColumns: [
        { title: "序号", dataIndex: "order", align: "center" },
        { title: "品牌", dataIndex: "brand", align: "center" },
        { title: "规格型号", dataIndex: "type", align: "center" },
        { title: "库存数量", dataIndex: "stockQty", align: "center" },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
        },
      ],
      options: [],
      totaldataSource: [],
    };
  },
  created() {
    this.getBatchNextCode();
    this.getOrderDetail();
  },
  computed: {
    stockQtyNum() {
      let d = 0;
      this.dataSource.forEach((item) => {
        d = parseInt(item.stockQty) + d;
      });
      return d;
    },
  },
  methods: {
    moment,
    // 材料分页
    changePageNum(p) {
      this.materialPageNum = p.current;

      this.getSkuList();
    },
    // 材料下拉
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    // 选择添加材料
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 材料表格数据
    getOrderDetail() {
      let id = this.$route.query.orderId;
      this.$api.getOrderDetail(id).then((res) => {
        if (res.code == "200") {
          if (res.data.orderInfo) {
            this.orderInfo = res.data.orderInfo;
            if (
              this.orderInfo.detailList &&
              this.orderInfo.detailList.length > 0
            ) {
              this.orderInfo.detailList.forEach((item, index) => {
                item.order = index + 1;
                item.key = item.id;
                item.stockQty = 0;
              });
            }
            this.dataSource = this.orderInfo.detailList;
          }

          // if (res.data.batchList) {
          //   res.data.batchList.forEach((item) => {
          //     var total = 0;
          //     if (item.detailList && item.detailList.length > 0) {
          //       item.detailList.forEach((items, index) => {
          //         total = parseInt(items.batchQty) + total;
          //         items.key = index + 1;
          //       });
          //       item.total = total;
          //     }
          //   });
          //   this.batchList = res.data.batchList;
          //   // console.log(this.batchList);
          // }
        }
      });
    },
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.form.getFieldsValue();
      console.log(formVal);
      let params = {};
      params.code = formVal.code;
      params.exceptedTime = formVal.exceptedTime
        ? formVal.exceptedTime.format("YYYY-MM-DD hh:mm:ss")
        : "";
      params.supplier = formVal.supplier;
      params.carNumber = formVal.carNumber;
      params.deliveryman = formVal.deliveryman;
      params.phone = formVal.phone;
      params.orderId = this.$route.query.orderId;
      params.detailList = [];

      this.dataSource.forEach((item) => {
        params.detailList.push({
          batchQty: item.stockQty,
          skuId: item.skuId,
        });
      });
      //console.log(params,this.dataSource);
      this.$api.addBatchOrder(params).then((res) => {
        if (res.code == "200") {
          this.$router.push({ path: "/mms/purchase/list" });
        }
      });
    },
    // 选择材料
    onChangeMaterial(value) {
      this.categoryId = value[value.length - 1];
      this.getSkuList();
    },
    getSkuList() {
      this.$api
        .getSkuList({
          categoryId: this.categoryId,
          current: this.materialPageNum,
        })
        .then((res) => {
          if (res.code == "200") {
            let records = res.data.records;
            this.materialTotal = res.data.total;
            if (records && records.length > 0) {
              if (this.selectedRowKeys.length > 0) {
                let arr = this.array_diff(records, this.selectedRowKeys); //去重
                arr.forEach((item, index) => {
                  item.order = index + 1;
                  item.key = item.id;
                  item.skuID = item.id;
                  item.num = 0;
                  item.orderQty = 0;
                  item.oriUnitPrice = 0;
                  item.discount = 0;
                  item.factPrice = 0;
                  item.actualAmount = 0;
                  item.actualPrice = 0;
                });
                this.materialSource = arr;
              } else {
                records.forEach((item, index) => {
                  item.order = index + 1;
                  item.key = item.id;
                  item.skuID = item.id;
                  item.num = 0;
                  item.orderQty = 0;
                  item.oriUnitPrice = 0;
                  item.discount = 0;
                  item.factPrice = 0;
                  item.actualAmount = 0;
                  item.actualPrice = 0;
                });

                this.materialSource = records;
              }
            }
          }
        });
    },
    // 添加保存材料
    addMaterial(e) {
      e && e.preventDefault();
      this.materialform.validateFields((err, values) => {
        if (!err) {
          // 当前材料id
          let nowmaterialId =
            values.companyNames[values.companyNames.length - 1];
          this.options.forEach((item) => {
            if (item.id == nowmaterialId) {
              this.addMaterialName = item.name;
              return;
            }
            if (item.children && item.children.length > 0) {
              item.children.forEach((items) => {
                if (items.id == this.categoryId) {
                  this.addMaterialName = items.name;
                  return;
                }
              });
            }
          });

          this.selectedRowKeys.forEach((item) => {
            this.materialSource.forEach((v) => {
              if (item == v.key) {
                this.dataSource.push(v);
              }
            });
          });
          let uniq = this.uniq(this.dataSource, "id");
          this.dataSource = uniq;
          this.dataSource.forEach((item, index) => {
            item.order = index + 1;
          });
          // let uniq = this.uniq(this.totaldataSource, "type");

          // this.dataSource = uniq;
          // this.dataSource.forEach((item,index)=>{
          //   item.key=index+1
          // })
          // this.transData=this.dataSource

          this.typeModal.visible = false;
        }
      });
    },
    // 新增
    addData() {
      this.typeModal.visible = true;
      this.materialSource = [];

      // this.selectedRowKeys = [];
      // // 查询材料
      this.$api.getCategoryList().then((res) => {
        if (res.code == "200") {
          let options = res.data;
          let arr = this.formTreeData(options);
          this.options = arr;
        }
      });
    },
    // 删除
    delDataSource(id) {
      // console.log(this.selectedRowKeys,id)
      this.dataSource = this.dataSource.filter((item) => {
        if (item.id != id) return item;
      });
      this.dataSource.forEach((item, index) => {
        item.order = index + 1;
      });
      this.selectedRowKeys = this.selectedRowKeys.filter((item) => {
        if (item != id) return item;
      });
    },
    // 批次编号
    getBatchNextCode() {
      this.$api.getBatchNextCode().then((res) => {
        if (res.code == "200") {
          this.code = res.data;
          this.supplier =
            this.$route.query.supplier == "undefined"
              ? ""
              : this.$route.query.supplier;
          // 初始化 编码和时间
          this.form.setFieldsValue({
            exceptedTime: moment(new Date(), "YYYY/MM/DD"),
            code: this.code,
            supplier: this.supplier,
          });
        }
      });
    },
    // 树状图
    formTreeData(arr) {
      arr.forEach((item) => {
        item.value = item.id;
        item.label = item.name;
        // item.scopedSlots = { title: "value" };
        if (item.children && item.children.length > 0) {
          this.formTreeData(item.children);
        }
      });
      return arr;
    },
    uniq(arr, key) {
      let newobj = {};
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        var item = arr[i];
        if (!newobj[item[key]]) {
          newobj[item[key]] = newArr.push(item);
        }
      }
      return newArr;
    },
    // 数组去重
    array_diff(a, b) {
      for (var i = 0; i < b.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (a[j].id == b[i]) {
            a.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return a;
    },
    onChange() {},
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100%;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
/deep/.ant-cascader-picker {
  background-color: #1c3d6c;
  border: none;
  outline: none;
}
/deep/.ant-input[disabled] {
  color: rgba(255, 255, 255, 0.8);
  background: #1c3d6c;
  cursor: not-allowed;
  opacity: 1;
}
/deep/.list-table .ant-table {
  color: #93bbf3;
}
/deep/.ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th {
  color: #fff;
}
/deep/.ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td {
  background: #1c3d6c;
}
/deep/.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #1c3d6c;
}
.addData {
  padding: 8px 0;
  border: 1px solid #1c3d6c;
  border-top: 0;
  //   border-bottom: 0;
}
.totalNum {
  border: 1px solid #1c3d6c;
  border-top: 0;
  height: 46px;
  span {
    width: 253px;
    text-align: center;
    color: #93bbf3;
    height: 46px;
    line-height: 46px;
  }
}

.textarea,
.form-container {
  margin-top: 40px;
}
</style>